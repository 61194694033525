import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import table from './table';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const validateAuthentication = async () => {
      const token = localStorage.getItem('token');
      const user = localStorage.getItem('user');

      if (token) {
        if(user){
          const requestBody = {
            token: token,
            user: user,
          };

          try {
            const response = await fetch('http://almablaze.cl:3001/Validate', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
            });

            if (response.ok) {
              setIsAuthenticated(true);
            } else {
	      localStorage.removeItem('token');
              localStorage.removeItem('user');
              setIsAuthenticated(false);
            }
		setIsLoading(false);
          } catch (error) {
            setIsAuthenticated(false);
	    console.log(error);
          }
        } else {
	  localStorage.removeItem('token');
          setIsAuthenticated(false);
	  setIsLoading(false);
	}
      } else if(user){
        localStorage.removeItem('user');
        setIsAuthenticated(false);
        setIsLoading(false);
      }
      setIsLoading(false);
    };
    
    validateAuthentication();
	  console.log('Estado de autenticaciÃ³n:', isAuthenticated);
  }, [isAuthenticated]);

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  console.log("isAuthenticated: ", isAuthenticated);
	console.log("isLoading: ", isLoading);
  return (
    <Router>
      <Routes>
	  <Route
        	path="/Login"
        	element={!isAuthenticated ? <Login /> : <Navigate to="/" />}
      	/>
        {/* Si el usuario estÃ¡ autenticado, renderiza el componente Home */}
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Home/>
            ) : (
              <Navigate to="/Login" />
            )
          }
        />
	  <Route
          path="/Tabla"
          element={
            isAuthenticated ? (
              <table/>
            ) : (
              <Navigate to="/Login" />
            )
          }
        />

        {/* Esto renderiza la pÃ¡gina de Home especÃ­ficamente en la ruta /Home */}
      </Routes>
    </Router>
  );
}

export default App;


