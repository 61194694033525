import React, {useEffect, useState} from 'react';
import { Link, useLocation}  from 'react-router-dom';
import './App.css';

function Home() {
    const location = useLocation();
    const token = location.state?.token || null;
    const user = location.state && location.state.user;

    const [tokenApi, setTokenApi] = useState('');
    const [categoria, setCategoria] = useState('')
    const [palabra, setPalabra] = useState('');
    const [intervaloMin, setIntervaloMin] = useState(0);
    const [intervaloMax, setIntervaloMax] = useState(0);
    const [buscar, setBuscar] = useState([]);

    const [loading, setLoading] = useState(true);

    const [seleccion, setSeleccion] = useState(0);
    const [subSeleccion, setSubSeleccion] = useState(0);

    const [Opciones, setOpciones] = useState(
        [
        {
        nombre: 'Nombre'
    },{
        nombre: 'Categoria'
    },{
        nombre: 'UbicaciÃ³n'
    },{
        nombre: 'Precio'
    },{
        nombre: 'Tipo de venta'
    },{
        nombre: 'MÃ©todos de envÃ­o'
    }
    ]);
    const [Categorias, setCategorias] = useState(
        [
        {
        nombre: 'Accesorios para VehÃ­culos'
    },{
        nombre: 'Agro'
    },{
        nombre: 'Alimentos y Bebidas'
    },{
        nombre: 'Animales y Mascotas'
    },{
        nombre: 'AntigÃ¼edades y Colecciones'
    },{
        nombre: 'Arte, LibrerÃ­a y CordonerÃ­a'
    },{
        nombre: 'BebÃ©s'
    },{
        nombre: 'Belleza y Cuidado Personal'
    },{
        nombre: 'CÃ¡maras y Accesorios'
    },{
        nombre: 'Celulares y TelefonÃ­a'
    },{
        nombre: 'ComputaciÃ³n'
    },{
        nombre: 'Consolas y Videojuegos'
    },{
        nombre: 'ConstrucciÃ³n'
    },{
        nombre: 'Deportes y Fitness'
    },{
        nombre: 'ElectrodomÃ©sticos'
    },{
        nombre: 'ElectrÃ³nica, Audio y Video'
    },{
        nombre: 'Herramientas'
    },{
        nombre: 'Hogar y Muebles'
    },{
        nombre: 'Industrias y Oficinas'
    },{
        nombre: 'Inmuebles'
    },{
        nombre: 'Instrumentos Musicales'
    },{
        nombre: 'Juegos y Juguetes'
    },{
        nombre: 'Libros, Revistas y Comics'
    },{
        nombre: 'Relojes y Joyas'
    },{
        nombre: 'Servicios'
    },{
        nombre: 'Souvenirs, CotillÃ³n y Fiestas'
    },{
        nombre: 'Vestuario y Calzado'
    }
    ]);

    if(!tokenApi) {
            // FunciÃ³n para realizar la solicitud GET a la API
            const fetchEventos = async () => {
                try {
                    const response = await fetch('http://almablaze.cl:3001/get-token'); // Ajusta la URL segÃºn tu configuraciÃ³n
                    if (!response.ok) {
                        throw new Error('Error al obtener eventos');
                    }
                    const data = await response.json();
                    setTokenApi(data.access_token); // Actualiza el estado con los eventos obtenidos
                    setLoading(false); // Cambia el estado de carga a falso
                    console.log(data);
                } catch (error) {
                    console.error('Error:', error);
                    setLoading(false); // Maneja el error y cambia el estado de carga a falso
                }
            };
            // Llama a la funciÃ³n para obtener los eventos cuando el componente se monta
            fetchEventos();
    }

    function Sort(array,Orden,Enfuncionde){
        switch (Enfuncionde) {
            case 'Nombre':
                array.sort((a, b) => {

                    if (a.title < b.title) {
                        return Orden-1;
                    }
                    if (a.title > b.title) {
                        return Orden;
                    }
                    return 0;
                });
                break;
            case 'Vendedor':
                array.sort((a, b) => {

                    if (a.seller.nickname < b.seller.nickname) {
                        return Orden-1;
                    }
                    if (a.seller.nickname > b.seller.nickname) {
                        return Orden;
                    }
                    return 0;
                });
                break;
            case 'Precio':
                array.sort((a, b) => {

                    if (a.price < b.price) {
                        return Orden*-1;
                    }
                    if (a.price > b.price) {
                        return Orden;
                    }
                    return 0;
                });
                break;
            case 'Vendidos':
                array.sort((a, b) => {

                    if (a.sold_quantity < b.sold_quantity) {
                        return Orden*-1;
                    }
                    if (a.sold_quantity > b.sold_quantity) {
                        return Orden;
                    }
                    return 0;
                });
                break;
            case 'Ubicacion':
                array.sort((a, b) => {

                    if (a.address.city_name < b.address.city_name) {
                        return Orden*-1;
                    }
                    if (a.address.city_name > b.address.city_name) {
                        return Orden;
                    }
                    return 0;
                });
                break;

            default:
                console.log('Opcion no reconocida');
        }
        setBuscar(array);
    }

    let datos = [];
    let busqueda = async () => {
        if (palabra === '') {
            buscar = [];
        } else {
            try {
                const requestBody = {
                    token: tokenApi,
                    categoria: categoria,
                    elemento : palabra,
                    precioMin: (intervaloMin)?intervaloMin:'NULL',
                    precioMax: (intervaloMax)?intervaloMax:'NULL'
                };
                const response = await fetch('http://almablaze.cl:3001/consultar-api', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody), // Enviar el cuerpo en el formato esperado
                });
                if (!response.ok) {
                    throw new Error('Error al obtener eventos');
                }
                const data = await response.json();
                datos = data.results;
                // Actualiza el estado con los eventos obtenidos
                Sort(data.results, -1, "Vendidos");
                console.log(data.results);
                setLoading(false); // Cambia el estado de carga a falso
                console.log(data);
            } catch (error) {
                console.error('Error:', error);
                setLoading(false); // Maneja el error y cambia el estado de carga a falso
            }
        }
    }


    let EventosHTML;
    switch (seleccion) {
        case 0:
            EventosHTML = (
                <div className="input-group col-md-4">
                    <input type="text" className="form-control" placeholder="Nombre" required=""
                           placeholder={palabra ? palabra :"Inserte nombre de producto"}
                           value={palabra}
                           onChange={
                               (e) => {
                                   setPalabra(e.target.value);
                               }
                           }/>
                    <button className="btn btn-secondary" onClick={() => {
                        busqueda()
                    }}>Buscar
                    </button>
                </div>
            );
            break;
        case 1:
            EventosHTML = (
                <div>
                    <hr className="my-4"/>
                    <div className="my-3 row">
                        {
                            Categorias.map((evento, index) => (
                                <div className="form-check col-md-2" key={index} onClick={() => setPalabra(evento.nombre)}>
                                    <input id={evento.nombre} name="Categoria" type="radio" className="form-check-input" required=""/>
                                    <label className="form-check-label" htmlFor="credit">{evento.nombre}</label>
                                </div>
                            ))
                        }
                    </div>
                    <button className="btn btn-secondary col-md-2" onClick={() => {
                        busqueda()
                    }}>Buscar
                    </button>
                </div>
            );
            break;
        case 2:
            EventosHTML = (
                <div></div>
            );
            break;
        case 3:
            EventosHTML = (
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="Precio-Minimo" className="form-label">Precio Minimo</label>
                        <input type="number" className="form-control" id="Precio-Minimo" placeholder=""
                            value={intervaloMin}
                            onChange={
                                (e) => {
                                    setIntervaloMin(e.target.value);
                                    if(intervaloMin>=intervaloMax){setIntervaloMax(intervaloMin)};
                                }
                            }
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="Precio-Maximo" className="form-label">Precio Maximo</label>
                        <input type="number" className="form-control" id="Precio-Maximo" placeholder=""
                               value={intervaloMax}
                               onChange={
                                   (e) => {
                                       setIntervaloMax(e.target.value);
                                       if(intervaloMin>=intervaloMax){setIntervaloMin(intervaloMax)};
                                   }
                               }
                        />
                    </div>
                    <button className="btn btn-secondary col-md-2" onClick={() => {busqueda()}}>Buscar
                    </button>
                </div>
            );
            break;
        case 4:
            EventosHTML = (
                <div></div>
            );
            break;
            EventosHTML = (
                <div></div>
            );
            break;
        case 5:
            EventosHTML = (
                <div></div>
            );
            break;
    }
    return (
        <div className="container">
            <main>
                <div className="row g-5">
                    <div className="">
                        <h4 className="mb-3">Formulario de Busqueda</h4>
                        <form className="card p-2 py-2"  onSubmit={(e) => {
                            e.preventDefault();
                        }}>
                            <div className="row g-3">
                                <div className="my-3 row">
                                    {
                                        Opciones.map((evento, index) => (
                                            <div className="form-check col-md-2" key={index} onClick={() => {
                                                setSeleccion(index);
                                                setCategoria(evento.nombre);
                                            }}>
                                                <input id={evento.nombre} name="Categoria de busqueda" type="radio" className="form-check-input" required=""/>
                                                    <label className="form-check-label" htmlFor="credit">{evento.nombre}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {EventosHTML}
                        </form>
                    </div>
                </div>
            </main>
            <table className="table table-striped table-dark">
                <thead>
                    <tr>
                        <th scope="col" >{buscar.length}</th>
                        <th scope="col" >Nombre</th>
                        <th scope="col" >Vendedor</th>
                        <th scope="col" >Precio Actual</th>
                        <th scope="col" >Vendidos</th>
                        <th scope="col" >Ubicacion</th>

                    </tr>
                </thead>
                <tbody>
                {buscar.map((evento, index) => (
                    <tr>
                        <th scope="col"><a href={evento.permalink}target="_blank">{index}</a></th>
                        <th scope="col">{evento.title}</th>
                        <th scope="col">{evento.seller.nickname}</th>
                        <th scope="col">{evento.price}</th>
                        <th scope="col">{evento.sold_quantity}</th>
                        <th scope="col">{evento.address.state_name}, {evento.address.city_name}</th>
                    </tr>
                ))
                }
                </tbody>
            </table>
        </div>
    );
};

export default Home;


