import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Formato esperado por la API
    const requestBody = {
      username: username,
      password: password,
    };

    if (window.location.protocol === 'https:') {
      console.log('La aplicaciÃ³n React se estÃ¡ ejecutando en HTTPS');
    } else {
      console.log('La aplicaciÃ³n React se estÃ¡ ejecutando en HTTP');
    }

    // Realizar una solicitud POST al servidor para iniciar sesiÃ³n
    const response = await fetch('http://almablaze.cl:3001/log', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody), // Enviar el cuerpo en el formato esperado
    });

    if (response.ok) {
      console.log('exito');
      const { token } = await response.json();
      // Almacenar el token en localStorage o en cookies
      localStorage.setItem('token', token);
      localStorage.setItem('user', username);
      // Redirigir a la pÃ¡gina de inicio o al panel de control, etc.
      navigate('/');
    } else {
      alert('Error de autenticaciÃ³n');
      // Manejar el error de autenticaciÃ³n
      console.error('Error de autenticaciÃ³n');
    }
  };

  return (
	  <div className = "row">
	  <div className = "col-lg-10 col-md-12">
    <section className="my-5 py-5">
      <div className="row d-flex justify-content-center">
        <div className="col-md-8 col-lg-7 col-xl-6">
          <img src="#" className="img-fluid" alt="Phone image" />
        </div>
        <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
          <form onSubmit={handleSubmit}>
            <div className="form-outline mb-4">
              <input
                type="text"
                id="form1Example13"
                placeholder="Nombre de usuario"
                className="form-control form-control-lg"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label className="form-label" htmlFor="form1Example13">
                Usuario
              </label>
              <div className="form-notch">
                <div className="form-notch-leading" style={{ width: '9px' }}></div>
                <div className="form-notch-middle" style={{ width: '88.8px' }}></div>
                <div className="form-notch-trailing"></div>
              </div>
            </div>

            <div className="form-outline mb-4">
              <input
                type="password"
                id="form1Example23"
                placeholder="ContraseÃ±a"
                className="form-control form-control-lg"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label className="form-label" htmlFor="form1Example23">
                ContraseÃ±a
              </label>
              <div className="form-notch">
                <div className="form-notch-leading" style={{ width: '9px' }}></div>
                <div className="form-notch-middle" style={{ width: '64.8px' }}></div>
                <div className="form-notch-trailing"></div>
              </div>
            </div>

            <div className="d-flex justify-content-around align-items-center mb-4">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="form1Example3"/>
                <label className="form-check-label" htmlFor="form1Example3">
                  Recuerdame
                </label>
              </div>
              <a href="#!">Forgot password?</a>
            </div>

            <button type="submit" className="align-btn-center btn-primary btn-lg btn-block">
              Sign in
            </button>

          </form>
        </div>
      </div>
    </section>
	  </div>
	  </div>
  );
}

export default Login;


